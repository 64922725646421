import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/myedibleenso.github.io/myedibleenso.github.io/src/components/BasicLayout.js";
import { HTMLTable } from '@blueprintjs/core';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <h1 {...{
      "id": "overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#overview",
        "aria-label": "overview permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Overview`}</h1>
    <p>{`In this unit, you'll identify career goals and begin developing an achievable path to carry you toward those goals.`}</p>
    <h1 {...{
      "id": "outcomes",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#outcomes",
        "aria-label": "outcomes permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Outcomes`}</h1>
    <p>{`By the end of this unit, you'll be able to ...`}</p>
    <ul>
      <li parentName="ul">{`describe your career goals`}</li>
      <li parentName="ul">{`develop an actionable, goal-oriented plan for career growth`}</li>
    </ul>
    <h2 {...{
      "id": "dont-panic",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#dont-panic",
        "aria-label": "dont panic permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Don't panic`}</h2>
    <p>{`It can feel daunting to find your first job or even an internship in HLT.  According to Crunchbase, more than a quarter million tech workers in the US were laid off between 2022 and 2023.`}<sup parentName="p" {...{
        "id": "fnref-1"
      }}><a parentName="sup" {...{
          "href": "#fn-1",
          "className": "footnote-ref"
        }}>{`1`}</a></sup>{`  `}</p>
    <p>{`While those in tech may face reduced job security, the choice of career offers exceptional job mobility and higher-than-average pay. `}</p>
    {/*  Often layoffs are accompanied by severance path (1-2 months of salary), and most find new jobs within a few months.   */}
    {/* 1 in 4 such workers found a job within a month, and the vast majority found new employment within 4-6 months. */}
    <p>{`For a person seeking their first job in industry, news of mass layoffs can be very discouraging.  How does a new graduate compete with professionals that have years of experience?  Put these thoughts aside and focus on the things you can control and influence.  Layoffs and restructuring in tech are not a new phenomenon. Approach this with a healthy dose of stoicism.  It's best to assume you will experience a layoff at some point in your career.  Knowing this can help you make more stategic decisions about your career and not be lulled into a false sense of security.  `}</p>
    {/* At a large corporation, you may be fortunate to find yourself on a great team that starts to feel like family.   */}
    {/* A great team can feel like family, but it's important to keep in mind that your employer is not a parent.   */}
    <p>{`Despite all the depressing news of tech layoffs, `}<strong parentName="p">{`interest in AI and NLP is at an all time high`}</strong>{`.  Plenty of companies beyond MANGA`}<sup parentName="p" {...{
        "id": "fnref-2"
      }}><a parentName="sup" {...{
          "href": "#fn-2",
          "className": "footnote-ref"
        }}>{`2`}</a></sup>{` are hiring and hungry for ways to improve their business with NLP/ML.`}</p>
    <p>{`Rather than despair over the terrible swing of the corporate pendulum, it's important to focus on what you can do to become more competitive and stand out.`}</p>
    <h2 {...{
      "id": "what-kind-of-career-do-you-want",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#what-kind-of-career-do-you-want",
        "aria-label": "what kind of career do you want permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`What kind of career do you want?`}</h2>
    <p>{`When hunting for your first job in tech, it may feel pointless to think about what your `}<em parentName="p">{`ideal`}</em>{` job looks like. Keep in mind that it's very unlikely you'll end up keeping your first job until retirement, so it's worth evaluating how the job you choose (either to pursue or accept) will carry you toward (or away from) your goals. There are many paths up the mountain. Without planning your route, it's difficult to accurately estimate when you can ever hope to reach the summit.`}</p>
    <p>{`You may not have given much thought to what your dream career resembles.  Without experience, it's difficult to really confirm your passions.  A "dream job" doesn't have to be a specific job title or company (though it can be).  It might be a matter of the domain (ex. law, medicine, finance, etc.), the work environment (ex. remote-first), or opportunities for growth within an organization.`}</p>
    <p><strong parentName="p">{`A clear sense of what you want will naturally make you more competitive for the jobs you pursue that align with your goal`}</strong>{`.  In part this is because you will be able to articulate why you value or are suited to a particular opportunity, but the real advantage is that a clear goal allows you chart a clear path for growth.  `}</p>
    <ul>
      <li parentName="ul">{`Do you want to be an `}<strong parentName="li">{`individual contributor`}</strong>{` (ex. engineer, applied scientist, etc.), `}<strong parentName="li">{`people manager`}</strong>{`, or a `}<strong parentName="li">{`founder`}</strong>{` (start your own company)?`}</li>
      <li parentName="ul">{`Do you want to work in a particular domain?`}</li>
      <li parentName="ul">{`Do you want to interact with customers/end users?`}</li>
      <li parentName="ul">{`Are you willing to move?  Are there any geographic restrictions on where you're willing (or able) to work?`}</li>
    </ul>
    <p>{`If you don't know the answers to any of these questions (or feel ambivalent about your answers to any of them), that's ok.  You can go about answering these questions based on passion, aptitude, or curiosity.`}</p>
    <h2 {...{
      "id": "identifying-opportunities-for-growth",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#identifying-opportunities-for-growth",
        "aria-label": "identifying opportunities for growth permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Identifying opportunities for growth`}</h2>
    <p>{`Once you have some initial answers, try identifying people that hold jobs like the one you're targeting. You can run job title or company searches using sites like LinkedIn for this.`}<sup parentName="p" {...{
        "id": "fnref-3"
      }}><a parentName="sup" {...{
          "href": "#fn-3",
          "className": "footnote-ref"
        }}>{`3`}</a></sup>{` After you've found a list of such people, compare their work history, education, and other aspects of their profiles.  Note any trends, particularly in the chain of positions that led them to their current job.`}</p>
    <div {...{
      "className": "admonition admonition-info alert alert--info"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
              }}></path></svg></span>{`Facing your LinkedIn aversion`}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`If the idea of clicking on the LinkedIn profiles of strangers bothers you, try to remind yourself that this is about research into your chosen career.  If you don't want to click on the profile of a stranger, try sending the person an invitation to connect and explain your objective.  While some people may ignore your request, you may end up make new connections that will help you in your journey.  `}</p></div></div>
    <div {...{
      "className": "admonition admonition-info alert alert--info"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
              }}></path></svg></span>{`Starting a company`}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`If your dream is to start a company, you can adapt the exercise.  What companies and founders most closely align with your vision? `}</p></div></div>
    <p>{`After noting employment trends, try searching for job postings for the earliest prior position that this set of people have in common.  You can use LinkedIn or sites like Indeed for this. `}</p>
    <div {...{
      "className": "admonition admonition-info alert alert--info"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
              }}></path></svg></span>{`What if they have little in common?`}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`If they don't have anything in common, you may need to refine and expand your search before trends/distributions start to become obvious.    `}</p></div></div>
    <p>{`After you've gathered descriptions of open positions that share a job title, try comparing the job descriptions and requirements to your own background and skills. Aside from stated `}<em parentName="p">{`minimum years of prior professional experience`}</em>{`, is there anything missing or unfamiliar?`}</p>
    <div {...{
      "className": "admonition admonition-info alert alert--info"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
              }}></path></svg></span>{`Job postings are wishlists`}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`Job postings are wishlists.  Don't be discouraged if you're missing or weak in one or more of the listed requirements in a job posting.`}</p></div></div>
    <p>{`Based on your comparision, begin brainstorming opportunities for growth.  Break these opportunities down into sub-goals, until you have at least three activities/achievable goals that you can begin pursuing in the next month.  `}</p>
    <div {...{
      "className": "admonition admonition-info alert alert--info"
    }}><div parentName="div" {...{
        "className": "admonition-heading"
      }}><h5 parentName="div"><span parentName="h5" {...{
            "className": "admonition-icon"
          }}><svg parentName="span" {...{
              "xmlns": "http://www.w3.org/2000/svg",
              "width": "14",
              "height": "16",
              "viewBox": "0 0 14 16"
            }}><path parentName="svg" {...{
                "fillRule": "evenodd",
                "d": "M7 2.3c3.14 0 5.7 2.56 5.7 5.7s-2.56 5.7-5.7 5.7A5.71 5.71 0 0 1 1.3 8c0-3.14 2.56-5.7 5.7-5.7zM7 1C3.14 1 0 4.14 0 8s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm1 3H6v5h2V4zm0 6H6v2h2v-2z"
              }}></path></svg></span>{`Measurable progress`}</h5></div><div parentName="div" {...{
        "className": "admonition-content"
      }}><p parentName="div">{`Resize and reevaluate until you have identified activities that will allow you to measure your progress.`}</p></div></div>
    <h3 {...{
      "id": "examples-of-actionable-goals",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#examples-of-actionable-goals",
        "aria-label": "examples of actionable goals permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Examples of actionable goals`}</h3>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`If you need to `}<strong parentName="p">{`demonstrate expertise in a problem domain`}</strong>{`, you might consider participating in relevant data science competitions on sites like Kaggle, writing about your process, and posting to sites like LinkedIn.  You can also set a goal to reach a particular rank on sites like Kaggle within `}<span parentName="p" {...{
            "className": "math math-inline"
          }}><span parentName="span" {...{
              "className": "katex"
            }}><span parentName="span" {...{
                "className": "katex-mathml"
              }}><math parentName="span" {...{
                  "xmlns": "http://www.w3.org/1998/Math/MathML"
                }}><semantics parentName="math"><mrow parentName="semantics"><mi parentName="mrow">{`n`}</mi></mrow><annotation parentName="semantics" {...{
                      "encoding": "application/x-tex"
                    }}>{`n`}</annotation></semantics></math></span><span parentName="span" {...{
                "className": "katex-html",
                "aria-hidden": "true"
              }}><span parentName="span" {...{
                  "className": "base"
                }}><span parentName="span" {...{
                    "className": "strut",
                    "style": {
                      "height": "0.4306em"
                    }
                  }}></span><span parentName="span" {...{
                    "className": "mord mathnormal"
                  }}>{`n`}</span></span></span></span></span>{` months.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`If you need to `}<strong parentName="p">{`demonstrate proficiency with a library or tool`}</strong>{`, think about a small project that you could undertake that would allow you to test and showcase your test your understanding.  Start with a private repo if you're uncomfortable "learning in the open."  Once you're satisfied, publicize the work (résumé line, LinkedIn timeline, Medium post, etc.).`}</p>
      </li>
    </ul>
    <h2 {...{
      "id": "next-steps",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#next-steps",
        "aria-label": "next steps permalink",
        "className": "md-header before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "height": "20",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "20"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Next steps`}</h2>
    {/* Complete https
     ```markdown
     ##
     ``` */}
    {/* What does that job look like?  
     - Find examples of listings for your dream job.   
      - If you're undecided, pick one position/title that appeals to you and dive deep.
      - What do these descriptions have in common?
     - Find examples of others with similar careers.    
      - What do their résumés have in common?
     - How can you grow professionally in a way that will bring you closer to your career goal?  Think of three measurable goals
      - Goals need to be measurable and achievable.  Rather than having "keeping up with new NLP research" as a goal, insead it could be "start a reading group with a friend" or "read and summarize one paper each week." */}

    <div {...{
      "className": "footnotes"
    }}>
      <hr parentName="div"></hr>
      <ol parentName="div">
        <li parentName="ol" {...{
          "id": "fn-1"
        }}><a parentName="li" {...{
            "href": "https://news.crunchbase.com/startups/tech-layoffs",
            "target": "_self",
            "rel": "nofollow"
          }}>{`https://news.crunchbase.com/startups/tech-layoffs`}</a><a parentName="li" {...{
            "href": "#fnref-1",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-2"
        }}>{`An acronym referencing the following large and "prestigious" tech companies: Meta, Amazon, Netflix, Google, and Apple.`}<a parentName="li" {...{
            "href": "#fnref-2",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
        <li parentName="ol" {...{
          "id": "fn-3"
        }}>{`If you want to simultaneously practice your HLT skills, you could try using an `}<a parentName="li" {...{
            "href": "https://github.com/tomquirk/linkedin-api",
            "target": "_self",
            "rel": "nofollow"
          }}>{`(unofficial) LinkedIn API library`}</a>{` to gather this information programmatically.`}<a parentName="li" {...{
            "href": "#fnref-3",
            "className": "footnote-backref"
          }}>{`↩`}</a></li>
      </ol>
    </div>
    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      